@use './scss/utils/' as *;

h1{
  font-size: rem(65);
  color: $c-cyan-dark;
}

.App{
  position: relative;
  @include breakpoint(medium){
    position: absolute;               
    top: 50%;                     
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: rem(900);
    margin-left: auto;
    margin-right: auto;
  }
  .brand{
    text-align: center;
    margin-bottom: rem(32);
    margin-top: rem(32);
    @include breakpoint(medium){
      margin-top: 0;
      margin-bottom: rem(82);
    }
  }

  .container{
    background-color: $c-white;
    border-top-left-radius: rem(18);
    border-top-right-radius: rem(18);
    padding: rem(24);
    height: 100%;
    @include breakpoint(medium){
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: rem(24);
      border-radius: rem(18);
      margin: 0 rem(16);
      & > *{
        flex: 1;
      }
    }
    .select-tip{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: rem(16);
      @include breakpoint(medium){
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .output{
      background-color: $c-cyan-dark;
      padding: rem(24);
      border-radius: rem(10);
      @include breakpoint(medium){
        display: flex;
        flex-direction: column;
        button{
          margin-top: auto;
        }
      }
      & > *{
        margin-top: rem(16);
      }
      &-item{
        display: flex;
        align-items: center;
        .value{
          color: $c-primary;
          font-size: rem(24);
          font-weight: 700;
          margin-left: auto;
          @include breakpoint(medium){
            font-size: rem(32);
          }
        }
      }
      .title{
        span{
          display: block;
          font-size: rem(14);
          color: $c-white;
          font-weight: 700;
        }
        span:last-child{
          color: $c-cyan-grayish-dark;
          font-size: rem(12);
        }
      }
    }
    .form-input{
      display: flex;
      flex-direction: column;
      gap: rem(24);
      margin-bottom: rem(24);
      label{
        color: $c-cyan-grayish-dark;
        display: inline-block;
        font-size: rem(14);
        font-weight: 700;
        margin-bottom: rem(4);
      }
    }

    .attribution { 
      grid-column: span 2;
      font-size: 11px; text-align: center; 
      a { 
        color: $c-primary;
        font-weight: 700;
      }
    }
  }
}
